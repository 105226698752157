import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { mapState } from "vuex";
import moment from 'moment';
import EventBus from "@/event/EventBus";

export default {

    extends: baseComponent,

    props: {
        listData: {
            type: Array,
            default: []
        },
    },

    data() {
        return {
            startTime: moment(),
            endTime: moment().add(7, 'days'),
        };
    },

    computed: {
        ...mapState({
            listServicePack: (state) => state.common.listServicePack,
        }),
    },

    created() {

    },

    mounted() {
        this.listData.forEach(element => {
            element.startTime = moment.utc(element.startTime);
            element.endTime = moment.utc(element.endTime);
        });
    },

    methods: {

        // Batch start time
        batchStartTime() {
            this.listData.forEach(element => {
                element.startTime = this.startTime
            });
        },

        // Batch end time
        batchEndTime() {
            this.listData.forEach(element => {
                element.endTime = this.endTime
            });
        },

        // Activate all
        activateAll() {
            this.listData.forEach(element => {
                element.activated = true;
            });
        },

        // Deactivate all
        deactivateAll() {
            this.listData.forEach(element => {
                element.activated = false;
            });
        },
    }
} 